import React, { FC, useEffect, useMemo, useState } from "react"
import { Cover, Filter, IMenuItemsList } from "shared"

import { ERecommendationType } from "@types"
import { useLocalizationContext, useWSContext } from "@context"
import { EventJournal, RecommendationJournal, useEventJournalAPI, useRecJournalAPI } from "@components"
import { useEquipmentShortListAPI, useTypedSelectorHook } from "@utils"
import { ControlButtons, TabsWrapper } from "./fragments"
import { useJournalExport, useRecVisibility } from "./utils"
import { ETabLinks } from "./_constants"

interface INotificationJournal {
  equipmentId?: number
  height?: number | string
}

/**
 * Компонент "Журнал уведомлений"
 *
 * @param {number} equipmentId - ID отдельного оборудования
 * @param {number | string} height - Задает высоту таблицы
 *
 * @returns {<INotificationJournal>} - FC<INotificationJournal>
 */
const NotificationJournal: FC<INotificationJournal> = ({ equipmentId, height }) => {
  const {
    l,
    l: { notification },
  } = useLocalizationContext()

  const { ws } = useWSContext()

  const {
    handbooks: { moduleEventTypeHandbook },
  } = useTypedSelectorHook((state) => state)
  const { equipmentShortList } = useEquipmentShortListAPI()

  const [activeTab, setActiveTab] = useState<ETabLinks>(ETabLinks.RECOMMENDATIONS)

  const recJournalAPI = useRecJournalAPI({ equipmentId, ws, isDisplayed: activeTab === ETabLinks.RECOMMENDATIONS })
  const { recommendationList, getRecommendationList, dataGridMethods: recFilterMethods } = recJournalAPI

  const eventJournalAPI = useEventJournalAPI({ equipmentId })
  const { getJournalEventList, dataGridMethods: eventFilterMethods } = eventJournalAPI

  const exportProps = useJournalExport({
    activeTab,
    recFilterMethods,
    eventFilterMethods,
    isEmptyTable: !recommendationList.results.length,
  })

  //   TODO: переделать
  const { selection, setSelection, ...visibilityProps } = useRecVisibility({
    recommendationList,
    getRecommendationList: () => getRecommendationList(recFilterMethods.queryString),
  })

  const eventTypeList = useMemo<IMenuItemsList | undefined>(
    () => moduleEventTypeHandbook?.map(({ key, title }) => ({ id: key, name: title })),
    [moduleEventTypeHandbook]
  )

  const recTypeList = useMemo<IMenuItemsList>(() => {
    const { recommendation, thresholds, fault } = l.recommendation

    return [
      { id: ERecommendationType.RECOMMENDATION.toLowerCase(), name: recommendation },
      { id: ERecommendationType.SETPOINTS.toLowerCase(), name: thresholds },
      { id: ERecommendationType.FAULT.toLowerCase(), name: fault },
    ]
  }, [l])

  useEffect(() => {
    getRecommendationList(recFilterMethods.queryString)
    getJournalEventList(eventFilterMethods.queryString)
  }, [])

  return (
    <Cover
      title={notification.notificationLog}
      showBorderTitle={true}
      autoHeight={height ? "autoHeight" : undefined}
      footerChildren={<ControlButtons {...{ ...exportProps, ...visibilityProps, l }} />}
    >
      <TabsWrapper
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        RecJournal={
          <>
            <Filter
              filterMethods={recFilterMethods}
              includeDatePicker
              includeEventTypeSelect
              includeEquipmentSelect={!equipmentId}
              equipmentList={equipmentShortList}
              eventTypeList={recTypeList}
              l={l}
            />
            <RecommendationJournal
              recJournalAPI={recJournalAPI}
              excludeColumnList={equipmentId ? ["equipment_name"] : undefined}
              selectionRows={selection}
              setSelectionRows={setSelection}
              height={height}
            />
          </>
        }
        EventJournal={
          <>
            <Filter
              filterMethods={eventFilterMethods}
              includeDatePicker
              includeEquipmentSelect={!equipmentId}
              includeEventTypeSelect
              equipmentList={equipmentShortList}
              eventTypeList={eventTypeList}
              l={l}
            />
            <EventJournal
              eventJournalAPI={eventJournalAPI}
              excludeColumnList={equipmentId ? ["equipment_name"] : undefined}
              height={height}
            />
          </>
        }
      />
    </Cover>
  )
}

export { NotificationJournal }
