import React from "react"
import { Stack } from "@mui/material"
import { ILocalizedFC } from "shared"

import { SButton } from "./_styles"

interface INotJournalControlButtons extends ILocalizedFC {
  handleExport: () => void
  isExportDisabled: boolean
  handleVisibilityChange: () => void
  isVisChangeDisabled: boolean
}

export const ControlButtons: React.FC<INotJournalControlButtons> = ({
  handleExport,
  isExportDisabled,
  handleVisibilityChange,
  isVisChangeDisabled,
  l,
}) => {
  const { ui, notification } = l

  return (
    <Stack direction="row" spacing={2}>
      <SButton
        variant="outlined"
        disabled={isVisChangeDisabled}
        onClick={handleVisibilityChange}
        children={notification.readAll}
      />
      <SButton variant="outlined" disabled={isExportDisabled} onClick={handleExport} children={ui.export} />
    </Stack>
  )
}
