import { Button, styled } from "@mui/material"
import { TabPanel } from "@mui/lab"

const STabPanel = styled(TabPanel)({
  padding: 0,
})

const SButton = styled(Button)({
  width: "160px",
})

export { STabPanel, SButton }
