import { useCallback, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { IExportTaskJournalPayload, IExportTaskRecommendationPayload, TUseDataGridMethods } from "shared"

import { exportTaskAPI } from "@api"
import { routePaths } from "@constants"
import { ETabLinks } from "../_constants"

interface IUseJournalExport {
  activeTab: ETabLinks
  equipmentId?: number
  recFilterMethods: TUseDataGridMethods
  eventFilterMethods: TUseDataGridMethods
  isEmptyTable: boolean
}

interface IUseJournalExportReturn {
  handleExport: () => void
  isExportDisabled: boolean
}

export function useJournalExport({
  activeTab,
  equipmentId,
  recFilterMethods,
  eventFilterMethods,
  isEmptyTable,
}: IUseJournalExport): IUseJournalExportReturn {
  const { postExportTaskRecommendation, postExportTaskJournal } = exportTaskAPI

  const navigate = useNavigate()

  const action = useMemo(
    () => (activeTab === ETabLinks.RECOMMENDATIONS ? postExportTaskRecommendation : postExportTaskJournal),
    [activeTab]
  )

  const payload: IExportTaskJournalPayload | IExportTaskRecommendationPayload = useMemo(() => {
    const { equipment, startTime, endTime, eventType } =
      activeTab === ETabLinks.RECOMMENDATIONS ? recFilterMethods.values : eventFilterMethods.values

    return {
      start_time: startTime,
      end_time: endTime,
      equipment: equipmentId || equipment !== "Все" ? +equipment : undefined,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      event_type: !!eventType && eventType !== "Все" ? eventType : undefined,
    }
  }, [activeTab, recFilterMethods, eventFilterMethods])

  const handleExport = useCallback(() => {
    action({ payload, onSuccess: () => navigate(routePaths.DOWNLOADS) })
  }, [action, payload])

  const isExportDisabled = useMemo(() => {
    const _isDirty =
      activeTab === ETabLinks.RECOMMENDATIONS
        ? isDirty(recFilterMethods.dirtyFields)
        : isDirty(eventFilterMethods.dirtyFields)

    return isEmptyTable || !_isDirty
  }, [activeTab, recFilterMethods, eventFilterMethods, isEmptyTable])

  return {
    handleExport,
    isExportDisabled,
  }
}

function isDirty(dirtyFields: Record<string, boolean>): boolean {
  const filteredKeys = Object.keys(dirtyFields).filter((key) => key !== "page" && key !== "limit")

  return !!filteredKeys.length
}
