import { useState, useCallback } from "react"
import { GridSelectionModel } from "@mui/x-data-grid"
import { TSetState } from "shared"

import { recsAPI } from "@api"
import { IRecommendationList } from "@types"

interface IUseRecVisibility {
  recommendationList: IRecommendationList
  getRecommendationList: () => void
}

interface IUseRecVisibilityReturn {
  handleVisibilityChange: () => void
  isVisChangeDisabled: boolean
  selection: GridSelectionModel
  setSelection: TSetState<GridSelectionModel>
}

export function useRecVisibility({
  recommendationList,
  getRecommendationList,
}: IUseRecVisibility): IUseRecVisibilityReturn {
  const [selection, setSelection] = useState<GridSelectionModel>([])

  const selectedRows = recommendationList.results.filter((rec) => selection.includes(rec.id))

  const handleVisibilityChange = useCallback(() => {
    const payload = selectedRows.map(({ id, protocol }) => ({
      id,
      protocol,
      visible: false,
    }))

    recsAPI.patchMultipleRecs({
      payload,
      onSuccess: () => {
        getRecommendationList()
        setSelection([])
      },
    })
  }, [selectedRows])

  return {
    handleVisibilityChange,
    isVisChangeDisabled: !selection.length,
    selection,
    setSelection,
  }
}
