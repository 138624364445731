import React, { SyntheticEvent, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Tab, Tabs } from "@mui/material"
import { TabContext } from "@mui/lab"
import { TSetState } from "shared"

import { useLocalizationContext } from "@context"
import { ETabLinks } from "../_constants"
import { STabPanel } from "./_styles"

interface ITabsWrapper {
  activeTab: ETabLinks
  setActiveTab: TSetState<ETabLinks>
  RecJournal: React.ReactNode
  EventJournal: React.ReactNode
}

export const TabsWrapper: React.FC<ITabsWrapper> = ({
  activeTab,
  setActiveTab,

  RecJournal,
  EventJournal,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const {
    l: { recommendation, events },
  } = useLocalizationContext()

  function handleChangeTab(_: SyntheticEvent, newValue: ETabLinks): void {
    setActiveTab(newValue)
    setSearchParams({ tab: newValue })
  }

  useEffect(() => {
    const params = searchParams.get("tab") as ETabLinks | null

    if (params !== null) {
      setActiveTab(params)
      return
    }

    setSearchParams({ tab: activeTab })
    setActiveTab(ETabLinks.RECOMMENDATIONS)
  }, [])

  return (
    <TabContext value={activeTab}>
      <Tabs variant="fullWidth" value={activeTab} onChange={handleChangeTab}>
        <Tab value={ETabLinks.RECOMMENDATIONS} label={recommendation.recommendationsAndNotifications} />
        <Tab value={ETabLinks.EVENTS} label={events.eventLog} />
      </Tabs>
      <STabPanel value={ETabLinks.RECOMMENDATIONS}>{RecJournal}</STabPanel>
      <STabPanel value={ETabLinks.EVENTS}>{EventJournal}</STabPanel>
    </TabContext>
  )
}
